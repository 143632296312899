import {Component, ViewChild, OnInit} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataService } from '../data-service/data.service';
import { Router, ActivatedRoute } from '@angular/router'; 
import { Key } from 'protractor';
import { Observable } from 'rxjs';
import { IData } from '../data.model';


/**
 * @title Data table with sorting, pagination, and filtering.
 */
@Component({
  selector: 'scannedforms',
  styleUrls: ['scannedforms.component.css'],
  templateUrl: 'scannedforms.component.html',
})
export class ScannedFormsComponent implements OnInit {
  
  // displayedColumns = [ 'reportid', 'reporttype', 'reportname', 'reportscript' ];  
  displayedColumns = [  'scannedforms' ];
  dataSource = new MatTableDataSource(); 

  // dataSource: MatTableDataSource<any>;
  // dataSource = new MatTableDataSource(this._dataService.getData());
  
  // members;

  // server folder 
  // imagepath = 'opt/images/';
  // imagetype = '.jpg'; 


  //local
  imagepath = 'assets/';
  imagetype = '.jpg'; 


  public wasnbr;

  sharedvariable: string; 


  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort,  {static: true}) sort: MatSort;

  constructor(private _dataService: DataService,
              private router: Router, private route: ActivatedRoute
            
            ) { }

  ngOnInit() {
    //console.log(" get reports call from ngOnInit ") ;


    this._dataService.currentMessage.subscribe(sharedvariable => this.sharedvariable = sharedvariable)

    // let wasnbr = parseInt(this.route.snapshot.paramMap.get('WASNBR'));
    // this.wasnbr = wasnbr ;


    // this.member = 1645; 
    // var search = this.wasnbr; 
  

    var search = this.sharedvariable ; 
    
    console.log("scanned forms component: " +search) ;

 
    this._dataService.getScannedForms(search).subscribe(data => 
      {
        (this.dataSource.data = data);
         this.getJsondata(data);
      })

    // this._dataService.getScannedForms(search).subscribe(data => this.getJsondata(data))

  }


  getJsondata(data) {
    // console.log(data)
  
  
    // for(var key in data[0]){ 
    //   console.log(key);
    //    }



     Object.keys(data).forEach(function(key) {

      for(var key in data[key]){ 
        console.log(key);
         }

      // console.log('Key : ' + key + ', Value : ' + data[key])
    })



   

    

//     var keys = Object.keys(data); 
// for(var i=0;i<keys.length;i++){
//     var key = keys[i];
//     console.log(key, data[key]);
//     }




// var values = Object.values(data); 
// for(var i=0;i<values.length;i++){
// var value = values[i];
// console.log(value);
// }








 
  }

  onSelect(imagepath, reportscript){
    // this.router.navigate(['/receipts', WNLMBRCDE, WNLFAMCDE]); 
    console.log  ("onselect from report panal: " +reportscript) ; 


    
      // let a = document.createElement("a");
      // document.body.appendChild(a);
      // a.href = imagepath+reportscript;
      // a.download = reportscript;
      //  a.click();
      // document.body.removeChild(a);
    



  }

  onSearch(search: string ){
      // search = 'Khan'; 
      
  //     search  = search.trim();
  //     search  = search.toLocaleLowerCase();
      
  //     console.log(search) ;

  //  this._dataService.getSearch(search).subscribe(data => this.dataSource.data = data);
  
  }


  /**
   * Set the paginator and sort after the view init since this component will
   * be able to query its view for the initialized paginator and sort.
   */

   
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
}