import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router) { }


    // isFullAccess: boolean = false;


    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot) {

        if (localStorage.getItem('currentUser') && this.isFullAccess()) {

            // get data from localStorage

            var curUser: string[] = JSON.parse(localStorage.getItem('currentUser'));

            // console.log("userAccess: " + JSON.stringify(usrAccess));
            // console.log("userAccess auth: " + JSON.stringify(curUser[0]["useraccess"]));


            // this.isFullAccess() ; 


            // if (curUser[0]["useraccess"].trim() == 'full') {
            //     this.isFullAccess = true;
            // }


            // this.uaccess = usrAccess.map(res => res);


            // list all keys fiels/columns of the table/json

            // Object.keys(usrAccess).forEach(function (key) {

            //     for (var key in usrAccess[key]) {
            //         console.log(key);
            //         // console.log('Key : ' + key + ', Value : ' + )
            //     }

            //     // console.log('Key : ' + key + ', Value : ' + data[key])
            // })

            // logged in so return true
            return true;
        }

        // not logged in so redirect to login page with the return url 

        
        // this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }



    isFullAccess(): boolean {

        var curUser = JSON.parse(localStorage.getItem('currentUser'));

        // console.log("curUser: " + JSON.stringify(curUser) + " useraccess: " + JSON.stringify(curUser[0]["useraccess"].trim()))

        // if (curUser[0]["useraccess"].trim() == 'full') return true;
        return true;

    }





}