import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor } from '@angular/common/http'
import { AuthenticationService } from '../_services';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  constructor(private injector: Injector) { }

  intercept(req, next) {

    let authserveice = this.injector.get(AuthenticationService)

    let tokenizedReq = req.clone({

      // headers: req.headers.set('Authorization', 'bearer ' + authserveice.getToken())

      setHeaders: { Authorization: `Bearer ${authserveice.getToken()}` }

    })

    return next.handle(tokenizedReq)

  }
}
