import { Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
// import { ChartsModule } from 'ng2-charts/ng2-charts';
import { DataService } from '../data-service/data.service';
import 'chartjs-plugin-labels';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthenticationService } from '../_services';
import { ActivatedRoute, Router } from '../../../node_modules/@angular/router';
import { Input, AfterViewInit, ViewChildren } from '@angular/core';
import { stringify } from 'querystring';



@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  cards = [
    { title: 'Card 1', cols: 2, rows: 1 },
    { title: 'Card 2', cols: 1, rows: 1 },
    { title: 'Card 3', cols: 1, rows: 1 },
    { title: 'Card 4', cols: 1, rows: 1 }
  ];

  chart: any = [];
  chartarry = [];
  chartdata: any;

  canvas: any;
  charttype: any;
  chartlabel1: any;
  chartlabel2: any;
  chartlabel3: any;
  charttitle: any 

  search = '';

  constructor(
    private _dataService: DataService,
    private route: ActivatedRoute, private router: Router,
    private authenticationService: AuthenticationService,
  ) {


    // this.charts = [
    //   {
    //     "id": "1",   // Just an identifier
    //     "chart": []            // The chart itself is going to be saved here
    //   },
    //   {
    //     "id": "2",
    //     "chart": []
    //   },
    //   {
    //     "id": "3",
    //     "chart": []
    //   }
    // ]

  }



  ngOnInit() {

    this._dataService.getAppData(this.search).subscribe(
      data => {
        this.chartdata = data;
        //  this.canvas = data.map(res => res.apppageview); 

        this.chartdata.forEach((data) => {


          this.canvas = data.apppageview;
          this.charttitle = data.appsqldesc;

          this.charttype = data.apppageviewtype;
          this.chartlabel1 = data.apppagelabel1;
          this.chartlabel2 = data.apppagelabel2;
          this.chartlabel3 = data.apppagelabel3;

          // console.log("XXXXXXXXXX CHART TYPE" + this.charttype)

          var cv = this.canvas;
          var charttitle = this.charttitle
          var charttype = this.charttype
          var chartlabel1 = this.chartlabel1
          var chartlabel2 = this.chartlabel2
          var chartlabel3 = this.chartlabel3


          // if (this.canvas == 'canvas1') {

          //   this._dataService.getChartData(data).subscribe(
          //     data => this.getJsondata1(data),
          //     err => {
          //       if (err instanceof HttpErrorResponse) {
          //         if (err.status === 403) {
          //           this.authenticationService.logout();
          //           this.router.navigate(['/login'])
          //         }
          //       }
          //     });
          // }


          // if (this.canvas == 'canvas2')
          //   this._dataService.getChartData(data).subscribe(data => this.getJsondata2(data));

          // if (this.canvas == 'canvas3')
          //   this._dataService.getChartData(data).subscribe(data => this.getJsondata3(data));

          // if (this.canvas == 'canvas4')
          //   this._dataService.getChartData(data).subscribe(data => this.getJsondata4(data));

          // if (this.canvas == 'canvas5')
          //   this._dataService.getChartData(data).subscribe(data => this.getJsondata5(data));

          // if (this.canvas == 'canvas6')
          //   this._dataService.getChartData(data).subscribe(data => this.getJsondata6(data));


          //   if (this.canvas == 'canvas7')
          //   this._dataService.getChartData(data).subscribe(data => this.getJsondata7(data));


          //   if (this.canvas == 'canvas8')
          //   this._dataService.getChartData(data).subscribe(data => this.getJsondata8(data));


          if (cv.match('canvas')) {
            //   console.log(" CVVVVVVV: " + cv)
            this._dataService.getChartData(data).subscribe(data => this.getJsondataAll(cv, charttitle, charttype, chartlabel1, chartlabel2, chartlabel3, data));
          }


        })

      },
      err => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 403) {
            this.authenticationService.logout();
            this.router.navigate(['/login'])
          }
        }
      });
  }



  getJsondataAll(cv, charttitle, charttype, chartlabel1, chartlabel2, chartlabel3, data) {

    this.canvas = cv;
    let chart_title = charttitle
    let chart_type = charttype
    let chart_lbl1 = chartlabel1
    let chart_lbl2 = chartlabel2
    let chart_lbl3 = chartlabel3

    let data1 = data.map(res => res.dbcol1);
    let data2 = data.map(res => res.dbcol2);
    let data3 = data.map(res => res.dbcol3);


    // chart
    // console.log("XXXXXXXXXX CHART TYPE" + charttype)


    this.chart = new Chart(this.canvas, {
      type: chart_type,
      data: {
        // labels: [this.chartData[0]['WMKFYR']],
        labels: data1,
        datasets: [
          {
            data: data2,
            backgroundColor: '#3c6f96',
            label: chart_lbl1,
            // barPercentage: 0.5,
            // borderColor: '#3cba9f',
            fill: false,

          },
          {
            data: data3,
            backgroundColor: '#eab060',
            label: chart_lbl2,
            fill: false
          },
        ]
      },
      options: {
        legend: {
          display: true,
        },

        title: {
          display: true,
          //  text: chart_title
        },

        scales: {
          xAxes: [{
            display: true,
            ticks: {
              beginAtZero: true
            }

          }],
          yAxes: [{
            display: true,
            ticks: {
              beginAtZero: true
            }
          }],

        },

      }
 

    })

    this.chartarry.push(this.chart)

  }

 
  // @ViewChildren('mycharts') allMyCanvas: any;  // Observe #mycharts elements
  // charts: any;    // Array to store all my charts

  // ngAfterViewInit() {
  //   let canvasCharts = this.allMyCanvas;  // Get array with all canvas
  //   canvasCharts.map((myCanvas, i) => {   // For each canvas, save the chart on the charts array 
  //      this.charts[i].chart = new Chart(myCanvas.nativeElement.getContext('2d'), {
  //        // ...
  //      }
  //   })
  // }



}
