import { Component, HostBinding, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '../../../node_modules/@angular/router';
// serivces
import { TabPanesService } from '../tab-control/tab-panes.service';
import { TabItemsService } from '../tab-control/tab-items.service';
// models
import { TabPaneItem } from '../tab-control/tab-pane-item';
import { TabItem } from '../tab-control/tab-item';
import { Routes, RouterModule } from '@angular/router';
import { DataService } from '../data-service/data.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Chart } from 'chart.js';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthenticationService } from '../_services';

@Component({
  selector: 'data-detail',
  templateUrl: './data-detail.component.html',
  styleUrls: ['./data-detail.component.css']
})

export class DataDetailComponent implements OnInit, OnDestroy {
  @HostBinding('attr.class') class = 'col-md-9 ml-sm-auto col-lg-10 pt-3 px-4';


  // expose a public array of tab pane items
  public tabPaneItems: TabPaneItem[];
  // expose a public array of tab items
  public tabItems: TabItem[];

  // displayedColumns = ['Name', 'Address'];
  dataSource = new MatTableDataSource();

  private member;

  private subCall: any;

  sharedvariable: string;

  constructor(private route: ActivatedRoute, private router: Router,
    private _dataService: DataService,
    private authenticationService: AuthenticationService,
    private tabPanesService: TabPanesService,
    private tabItemsService: TabItemsService) {

  }

  public cardData: any = [];

  //dates 
  public invoicedate: string;
  public transactiondate: string;
  public paymentdate: string;
  public dueDate: Date;

  public ngOnInit(): void {

    let membercode = parseInt(this.route.snapshot.paramMap.get('search'));
    this.member = membercode;

    var search = this.member;

    console.log(search);

    this.subCall = this._dataService.currentMessage.subscribe(sharedvariable => this.sharedvariable = sharedvariable)


    this.subCall = this._dataService.getInvoiceHeader(search).subscribe(
      data => this.getJsondata(data),
      err => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 403) {
            this.authenticationService.logout();
            this.router.navigate(['/login'])
          }
        }
      });

    this.tabItems = this.tabItemsService.getTabItems();
    this.tabPaneItems = this.tabPanesService.getTabPanes();
  }


  getJsondata(data) {

    console.log("invoice header : " + JSON.stringify(data)); 

    this.cardData = data;



    // split  date 
    this.invoicedate = this.cardData[0].IHInvoiceDate; 
    // console.log (this.invoicedate)
    if (this.invoicedate!=null) this.invoicedate = this.invoicedate.split('T')[0]; 
    // console.log (this.invoicedate)


    this.dueDate = new Date(Date.parse( this.invoicedate));
    this.dueDate.setDate( this.dueDate.getDate() + 31 );

      // split  date 
      this.transactiondate = this.cardData[0].IHTransactionDate; 
      // console.log (this.invoicedate)
      if (this.transactiondate!=null) this.transactiondate = this.transactiondate.split('T')[0]; 
      // console.log (this.invoicedate)

        // split  date 
    this.paymentdate = this.cardData[0].IHPaymentDate; 
    // console.log (this.invoicedate)
    if (this.paymentdate!=null) this.paymentdate = this.paymentdate.split('T')[0]; 
    // console.log (this.invoicedate)

    // move wasnbr from json to variable
    // this.sharedvariable = data.map(res => res.wasnbr);

    // change shared variable value which can be accessed from all components
    // this._dataService.changeValue(this.sharedvariable);

    // console.log (this.message) ;


    // let amounts = data.map(res => res.WNLMBRCDE);

    // let allyears = data.map(res => res.WMKFYR)


    // let years = []
    // allyears.forEach((res) => {
    //     let jsdate = new Date(res * 1000)
    //     years.push(jsdate.toLocaleTimeString('en', { year: 'numeric', month: 'short', day: 'numeric' }))
    // })


    //  // Linchart

    //  this.cardData = new Chart('canvas', {
    //   type: 'bar',
    //   data: {
    //     // labels: [this.chartData[0]['WMKFYR']],
    //     labels: allyears, 
    //     datasets: [
    //       {
    //         data: amounts,
    //          backgroundColor: ['brown', 'brown','brown','brown','brown','brown','brown','brown','brown','brown','brown','brown','brown'],
    //         // borderColor: '#3cba9f',
    //           fill: false

    //       },
    //       // {
    //       //   data: temp_min,
    //       //   borderColor: '#ffcc00',
    //       //   fill: false
    //       // },
    //     ]
    //   },
    //   options: {
    //     legend: {
    //       display: false
    //     },
    //     scales: {
    //       xAxes: [{
    //         display: true
    //       }],
    //       yAxes: [{
    //         display: true
    //       }]
    //     }
    //   }
    // })


    // for(var key in data[0]){ 
    //   console.log(key);
    //    }

    // let body = JSON.stringify(data); 

    Object.keys(data).forEach(function (key) {

      for (var key in data[key]) {
        // console.log(key);
      }

      // console.log('Key : ' + key + ', Value : ' + data[key])
    })
  }



  printView() {

    var _invoiceID = this.cardData[0].IHInvoiceID; 
    this.router.navigate(['/printview', _invoiceID]);
  }


  public ngOnDestroy(): void {

    this.subCall.unsubscribe();

  }
}







// export class DataDetailComponent implements OnInit {

// navLinks = [

// { path: '/receipts', label: 'Receipts'},
// { path: '/reports', label: 'Reports'},
// { path: 'Jeemforms', label: 'Jeem Forms'}

// ];

// public memberCode; 

//   constructor(private route: ActivatedRoute) { }

//   ngOnInit() {

//     let memeber = parseInt(this.route.snapshot.paramMap.get('membercode')); 

//   this.memberCode = memeber; 

//   console.log(this.memberCode);


//   }

// }
