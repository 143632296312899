import { Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataService } from '../data-service/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthenticationService } from '../_services';

/**
 * @title Data table with sorting, pagination, and filtering.
 */
@Component({
  selector: 'data-detail-list',
  styleUrls: ['data-detail-list.component.css'],
  templateUrl: 'data-detail-list.component.html',
})
export class DataDetailListComponent implements OnInit {

  displayedColumns = [];
  data = [];
  dataSource = new MatTableDataSource();
  // dataSource = new MatTableDataSource(ELEMENT_DATA);
  search: any ; 

  private subCall: any;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(private _dataService: DataService,
    private authenticationService: AuthenticationService,
    private router: Router, private route: ActivatedRoute
  ) { }


  ngOnInit() {
    // search = 'Khan'; 


    this.search = parseInt(this.route.snapshot.paramMap.get('search'));

    // console.log("receipts component: " +search) ;

    this._dataService.getDetail(this.search).subscribe(
      data => this.dataSource.data = data,
      err => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 403) {
            this.authenticationService.logout();
            this.router.navigate(['/login'])
          }
        }
      });

    this.subCall = this._dataService.getDetail(this.search).subscribe(data => this.getColHeadings(data));

  }


  // get data column headings 
  getColHeadings(data) {

    this.data = data;

    let cols = [];

    // let body = JSON.stringify(data); 
    
    cols.push('Action' );

    for (var key in data[0]) {
      // console.log(key);
      cols.push(key);
    }

    if (cols != []) this.displayedColumns = cols;
    // this.dataSource = new MatTableDataSource(data);

    this.dataSource.data = data
    
    // console.log(data);
    // ELEMENT_DATA.push(data);

    // console.log(ELEMENT_DATA);
    // this.dataSource = new MatTableDataSource(ELEMENT_DATA);


    // console.log(this.displayedColumns);
    // console.log(this.data);
  }

  addElement() {
    this.data.push(this.data)
    this.dataSource.data = this.data
  
  }

  onAdd() {
    this._dataService.editHeaderRecord = true; 
    this._dataService.addDetailRecord = true; 
    this._dataService.addHeaderRecord = false; 
    this._dataService.editDetailRecord = false; 
    this.router.navigate(['/createinvoice', this.search]);
  }

  onEdit(_screenParameter) {
    this._dataService.editHeaderRecord = true; 
    this._dataService.editDetailRecord = true;
    this._dataService.addDetailRecord = false; 
    this._dataService._screenParameter = _screenParameter;  
    this.router.navigate(['/createinvoice', this.search, _screenParameter ]);
  }

  onSelect(membercode) {
    // this.router.navigate(['/dataList', membercode]); 
  }

  /**
   * Set the paginator and sort after the view init since this component will
   * be able to query its view for the initialized paginator and sort.
   */


  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
}

