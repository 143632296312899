import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../_services';
import { DataService } from '../data-service/data.service';
import {RouterOutlet} from '@angular/router';
 


@Component({
  selector: 'main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.css']
})
export class MainNavComponent implements OnInit {

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  username: string;
  public menuData: any = [];
  tableName: string; 

  constructor(private breakpointObserver: BreakpointObserver,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private _dataService: DataService
  ) { }


  ngOnInit() {

    this.getMenuData();
 
  }

 


  getMenuData = async () => {

    this.menuData = await this.getMenuList();
   // this.isLoggedIn();
    // if (this.isFullAccess) this.getMenuData();
   // this.isFullAccess();

  }

  getMenuList() {

    return new Promise((resolve, reject) => {

      var tableName = 'menu'
      this._dataService.getTableList(tableName)
        .subscribe(data => {
          resolve(data);
         this.menuData[0] = data;
          // resolve(this.menuData[0])
         this.isLoggedIn();
         // if (this.isFullAccess) this.getMenuData();
         this.isFullAccess();
        }
        );
    });
  }

  isLoggedIn() {

    // return true if loggin in 
    if (localStorage.getItem('currentUser')) {

      var curUser = JSON.parse(localStorage.getItem('currentUser'));
      this.username = curUser[0].FirstName.trim();
      this.isFullAccess();
      return true;
    }
    return false;
  }


  isFullAccess() {

    // console.log("dashboard access: " + this.authenticationService.isFullAccess())

    // return true if dashbaord access  
    return this.authenticationService.isFullAccess();

  }

  onLogout() {
    // remove user from local storage to log user out

    this.authenticationService.logout();

    // clear search shared value before logging off 
    this._dataService.changesearchValue("");

    this.router.navigate(['/login']);

  }
}
