import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { DataService } from '../data-service/data.service';
import { Router, ActivatedRoute } from '@angular/router'
import { HttpErrorResponse } from '@angular/common/http';
import { AuthenticationService } from '../_services';
import { MatTableDataSource } from '@angular/material/table';
import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';



@Component({
  selector: 'print-view',
  templateUrl: './print-view.component.html',
  styleUrls: ['./print-view.component.css']
})
export class PrintViewComponent implements OnInit {


  @ViewChild('contentToConvert' , {static: true}) contentToConvert: ElementRef;



  constructor(private _dataService: DataService,
    private authenticationService: AuthenticationService,
    private router: Router, private route: ActivatedRoute
  ) { }

  displayedColumns = [];
  data = [];
  dataSource = new MatTableDataSource();
  private subCall: any;

  public companyData: any = [];
  public customerData: any = [];
  public invoiceHeaderData: any = [];
  public invoiceDetailData: any = [];

  public invoiceNbr: number = 0;
  public customerID: number = 0;
  invoiceDescription: string = '';
  companyName: string = '';
  dueDate: Date;

  //dates 
  public invoicedate: string;
  public transactiondate: string;
  public paymentdate: string;

  ngOnInit() {


    var search = parseInt(this.route.snapshot.paramMap.get('search'));

    // console.log("receipts component: " +search) ;

    this._dataService.getInvoiceHeader(search).subscribe(
      data => this.dataSource.data = data,
      // data => this.invoiceHeaderData = data,
      err => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 403) {
            this.authenticationService.logout();
            this.router.navigate(['/login'])
          }
        }
      });

    this.subCall = this._dataService.getInvoiceHeader(search).subscribe(data => this.prepareInvoiceHeader(data));

    //-
    // console.log("receipts component: " +search) ;
    // get customer details 

    // console.log("invoice customer ID: " + this.customerID);

    // search = this.invoiceHeaderData[0].IHCustomerID;
    // console.log("customer search: " + search);

    // this._dataService.getCustomerDetail(search).subscribe(
    //   data => this.dataSource.data = data,
    //   err => {
    //     if (err instanceof HttpErrorResponse) {
    //       if (err.status === 403) {
    //         this.authenticationService.logout();
    //         this.router.navigate(['/login'])
    //       }
    //     }
    //   });

    // this.subCall = this._dataService.getCustomerDetail(search).subscribe(data => this.prepareCustomer(data));

    // get company details 
    search = 1;

    // console.log("receipts component: " +search) ;

    this._dataService.getCompanyDetail(search).subscribe(
      data => this.dataSource.data = data,
      err => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 403) {
            this.authenticationService.logout();
            this.router.navigate(['/login'])
          }
        }
      });

    this.subCall = this._dataService.getCompanyDetail(search).subscribe(data => this.prepareCompany(data));


  }

  // ngAfterViewInit() {
  //   console.log("afterinit");
  //   setTimeout(() => {
  //     console.log(this.contentToConvert.nativeElement.innerText);
  //   }, 1000);
  // }

  //-
  // prepare invoice header data 

  prepareInvoiceHeader(data) {

    // console.log("invoice header for PDF: " + JSON.stringify(data));

    this.invoiceHeaderData = data;

    // this.invoiceHeaderData = this.data ; 
    this.customerID = this.invoiceHeaderData[0].IHCustomerID;

    this.invoiceNbr = this.invoiceHeaderData[0].IHInvoiceID;

    this.invoiceDescription = this.invoiceHeaderData[0].IHInvDescription;

    // split  date 
    this.invoicedate = this.invoiceHeaderData[0].IHInvoiceDate;
    // console.log (this.invoicedate)

    this.dueDate = new Date(Date.parse(this.invoicedate));
    this.dueDate.setDate(this.dueDate.getDate() + 30);



    //var numberOfDaysToAdd = 30;
    //console.log("DDDAAATTEEEEEE***: " + this.dueDate); 


    if (this.invoicedate != null) this.invoicedate = this.invoicedate.split('T')[0];


    // console.log (this.invoicedate)

    // split  date 
    this.transactiondate = this.invoiceHeaderData[0].IHTransactionDate;
    // console.log (this.invoicedate)
    if (this.transactiondate != null) this.transactiondate = this.transactiondate.split('T')[0];
    // console.log (this.invoicedate)

    // split  date 
    this.paymentdate = this.invoiceHeaderData[0].IHPaymentDate;
    // console.log (this.invoicedate)
    if (this.paymentdate != null) this.paymentdate = this.paymentdate.split('T')[0];
    // console.log (this.invoicedate)

    Object.keys(data).forEach(function (key) {

      for (var key in data[key]) {
        // console.log(key);
      }

      // console.log('Key : ' + key + ', Value : ' + data[key])
    })

    var search = this.customerID;
    this._dataService.getCustomerDetail(search).subscribe(data => this.prepareCustomer(data));

    var search = this.invoiceNbr;
    this._dataService.getDetail(search).subscribe(data => this.prepareInvoiceDetail(data));


  }


  prepareCompany(data) {

    // console.log("company data for PDF: " + JSON.stringify(data));

    this.companyData = data;

    // console.log("COMPANY DATA *****"+ JSON.stringify(this.companyData[0].CCName))

    this.companyName = JSON.stringify(this.companyData[0].CCName);

    Object.keys(data).forEach(function (key) {

      for (var key in data[key]) {
        // console.log(key);
      }

      // console.log('Key : ' + key + ', Value : ' + data[key])
    })
  }


  prepareCustomer(data) {

    console.log("customer data for PDF: " + JSON.stringify(data));

    this.customerData = data;

    Object.keys(data).forEach(function (key) {

      for (var key in data[key]) {
        // console.log(key);
      }

      // console.log('Key : ' + key + ', Value : ' + data[key])
    })
  }

  prepareInvoiceDetail(data) {

    //console.log("invoice detail data for PDF: " + JSON.stringify(data));


    this.invoiceDetailData = data;

    //  console.log("invoice detail data for PDF: " + this.invoiceDetailData);
    Object.keys(data).forEach(function (key) {

      for (var key in data[key]) {
        // console.log(key);
      }

      //    console.log('Key INVOICE DETAILLLLLLLLL: ' + key + ', Value : ' + data[key])
    })
  }

  public downloadPDF() {



    // const doc = new jsPDF();

    // const specialElementHandlers = {
    //   '#editor': function (element, renderer) {
    //     return true;
    //   }
    // };

    // const contentToConvert = this.contentToConvert.nativeElement

    // doc.fromHTML(contentToConvert.innerHTML, 10, 10, {
    //   width: 220,
    //   'elementHandlers': specialElementHandlers
    // });

    // doc.save('tableToPdf.pdf');



    // // Save the PDF

    var data = document.getElementById('contentToConvert');
    html2canvas(data).then(canvas => {
      // Few necessary setting options
      var imgWidth = 220;
      //var pageHeight = 295;
      var imgHeight = 275;
      // var imgHeight = canvas.height * imgWidth / canvas.width;
      // var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')

      let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 15;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save(this.companyName + 'Invoice_' + this.invoiceDescription + '.pdf'); // Generated PDF

    });


  }

  public ngOnDestroy(): void {

    this.subCall.unsubscribe();

  }

}
