import { Injectable } from '@angular/core';

// import { CounterComponent } from '../counter/counter.component';
// import { FetchDataComponent } from './../fetch-data/fetch-data.component';
// import { HomeComponent } from '../home/home.component';

import { DataDetailListComponent } from './../data-detail-list/data-detail-list.component';
import { DataListComponent } from './../data-list/data-list.component'
import { DataDetailComponent } from './../data-detail/data-detail.component'; 

import { TabItem } from './tab-item';

// when you create a service make sure to register it

@Injectable()
export class TabItemsService {
    public getTabItems(): TabItem[] {
        return [
            
            new TabItem('Detail', true),
            new TabItem('Scanned Forms', false),
            // new TabItem('Reports', false)
            
        ];
    }
}