import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import {
  Country,
  UsernameValidator,
  PasswordValidator,
  ParentErrorStateMatcher,
  PhoneValidator
} from '../validators';
import { FocusMonitor } from '@angular/cdk/a11y';

import { DataService } from '../data-service/data.service';
// import { DataDetailListComponent } from '../data-detail-list/data-detail-list.component';

import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import { Router, ActivatedRoute } from '@angular/router';
import { isEmpty } from 'rxjs/operators';


import { FieldConfig } from "../field.interface";
import { DynamicFormComponent } from "../components/dynamic-form/dynamic-form.component";
import { CompileShallowModuleMetadata } from '@angular/compiler';
import { Button, element } from 'protractor';
import { async } from 'q';
import { timingSafeEqual } from 'crypto';
import { keyframes } from '@angular/animations';
import { table } from 'console';
import { Location } from '@angular/common';
import { of } from 'rxjs';


@Component({
  selector: 'app-record-panel',
  templateUrl: './record-panel.component.html',
  styleUrls: ['./record-panel.component.css']
})
 

 
export class RecordPanelComponent implements OnInit {

  @ViewChild(DynamicFormComponent, { static: true }) form: any;
  regConfig: FieldConfig[] = [];

  formGrp: FormGroup;
  public search: any;
  public table: any;
  public module: any;
  public _id: any;
  public toPrint: any;

  fields;

  // public paneldata: any[] = [];
  public fieldsData: any = [];
  public validations: any = [];
  public options: any = [];
  public dropdownData: any = [];
  public fieldsDatax: any = [];
  public KeyFieldName: String;

  fieldValidationsData = [];
  public fieldKeys: any = [];
  _keyfield: any;

  saveSearchValue: string;
  sharedSearch: string;
  saveParmValue: string;
  sharedParm: string;

  constructor(private fb: FormBuilder,
    public _dataService: DataService,
    private router: Router,
    private route: ActivatedRoute,
    private elRef: ElementRef,
    private _focusMonitor: FocusMonitor,
    private location: Location
  ) { }


  ngOnInit() {

    this._dataService.currentSearch.subscribe(sharedSearch => this.sharedSearch = sharedSearch)
    this._dataService.currentParms.subscribe(sharedParm => this.sharedParm = sharedParm)
    this.search = parseInt(this.route.snapshot.paramMap.get('search'));
   
    this.module = this.sharedSearch
    this.table  = this.sharedParm;
    this._id = this.search;

    this.getScreenData()

  }


  //-- Main routine

  getScreenData = async () => {

    var _data: any;

    if (this.search !== 0) {

      _data = await this.getSelectedRecord()
      _data = await this.getFieldKeys(_data)
      if (_data.length > 0) await this.assigndynamicfields();

    } else {

      _data = await this.createNewRecord()
      _data = await this.newFieldKeys(_data)

      // async orders
      // of(this.assigndynamicfields()); 

    // of(this.getOrders()).subscribe(orders => {
    //   this.orders = orders;
    // });

      // for all empty fields to load before loading function keys 
      setTimeout(() => {
         this.assigndynamicfields();
      }, 400);

   //   await this.assigndynamicfields();  /// this works but doesnt load all fields 

    }

  }

  getSelectedRecord() {

    return new Promise((resolve, reject) => {

      var tableName = this.module
     
      this._dataService.getTableRecord(this.search, tableName)
        .subscribe(data =>
          resolve(data));
    });
  }

  createNewRecord() {

    return new Promise((resolve, reject) => {

      var tableName = this.table
      
      this._dataService.getFields(this.search, '', tableName, '')
        .subscribe(data =>
          resolve(data));
    });
  }

  getFields(id_Field, tableName, fieldName) {

    return new Promise((resolve, reject) => {

      this._dataService.getFields(this._id, id_Field, tableName, fieldName)
        .subscribe(data =>
          resolve(data));
    });
  }

  getTableList(tableName) {

    return new Promise((resolve, reject) => {

      this._dataService.getTableList(tableName)
        .subscribe(data =>
          resolve(data));
    });
  }

  getFieldKeys = async (data: any) => {

    return new Promise((resolve, reject) => {

      let that = this;

      this._keyfield = that.search
      var id_Field = '';
      var x = 0;

      Object.keys(data).forEach(async function (key) {

        for (var key in data[key]) {

          if (x == 0) {
            id_Field = key;
            that.KeyFieldName = id_Field;
          }

          // console.log('field name : ' + key + ', Value : ' + data[0][key])
 
          var tableName = that.table
          var fieldName = key

          // await that.loadFieldValidations(fieldName);
          await that.loadkeys(id_Field, tableName, fieldName);
          x += 1;
        }
        resolve(that.fieldsData)
      })

    })
  }

  newFieldKeys = async (data: any) => {

    return new Promise((resolve, reject) => {

      let that = this;

      // console.log('NEW  fields : ' + JSON.stringify(data))

      this._keyfield = 'NEW';
      var id_Field = '';
      var x = 0;
      this._id = 9999;

      Object.keys(data).forEach(async function (key) {

        if (x == 0) {
          var id_Field = data[key].name;
          that.KeyFieldName = id_Field;
        }

        var tableName = data[key].tablename;
        var fieldName = data[key].name;

        // console.log('fields : ' + fieldName + ', Value : ' + data[0][key])

        that.search = 9999;
        // that.loadFieldValidations(fieldName);
        await that.loadkeys(id_Field, tableName, fieldName);
        x += 1;

      })
        resolve(that.fieldsData)

    })

  }

  // get fields for the page 
  loadkeys = async (id_Field, tableName, fieldName) => {

    var data: any;
    data = await this.getFields(id_Field, tableName, fieldName)

    if (data.length > 0) {

      if (await fieldName === data[0].name) {

        data[0].validations = this.validations[0];
        data[0].options = [];

        //----- Load dropdown options 

        if (data[0].type == 'select') {

          var dropTableName  = data[0].droptablename;
          var dropTableField = data[0].droptablefield;

          data[0].options = await this.loadFieldOptions(dropTableName, dropTableField)}
        
        }

          data[0].validations = await this.loadFieldValidations(fieldName);

        //--- push data to fields array 

        this.fieldsData.push(data[0]);
        this.validations = [];

    }

  };

  // get dropdown option for each field
  loadFieldOptions = async (droptablename: any, droptablefield: any) => {

    return new Promise((resolve, reject) => {

      var tableName = droptablename;
      var fieldName = droptablefield;
      this._dataService.getTableListByField(tableName, fieldName).subscribe(async data => {

     // this._dataService.getTableList(tableName).subscribe(async data => {


        if (data.length > 0) {

          var _data = data;
          var _options: any

          Object.keys(_data).forEach(function (Key) {
            for (var key in _data[Key]) {

              if (fieldName.toUpperCase() == key.toUpperCase()) {

                _data = data.map(res => res[key]);
                _options = _data;
                break
              }
            }
          })

          this.options = _options;
          resolve(this.options)

        } else {
          this.options[0] = []

        }

      })

    })

  };

  // get validations for each field
  loadFieldValidations = async (fieldName) => {

    // setTimeout(() => {

    return new Promise((resolve, reject) => {

      this.validations = [];

      this._dataService.getFieldValidations(fieldName).subscribe(async  data => {
        if (data.length > 0) {
          // alert(this.validations[0])
          this.validations[0] = data;
          resolve(this.validations[0])

        } else {
          resolve(this.validations[0] = [])
          // alert(this.validations[0])
        }
        // Object.keys(data).forEach(function (key) {
        //   for (var key in data[key]) {
        //     // console.log('fields : ' + key + ', Value : ' + data[0][key])    
        //   }
        // })
      });

    })

    // }, 100);

  };

  // assign dynamic fields 
  assigndynamicfields = async () => {

    // setTimeout(() => {

    //call service and assign dynamic config data
    this.fieldsData.push(
    {
      name: "save",
      type: "button",
      label: "Save"
    },

    { 
     name: "copy",
     type: "button",
     label: "Copy"
    },

    {
     name: "delete",
     type: "button",
     label: "Delete"
    },

    );


    if (this.module=="import")
       { 
         this.fieldsData.push(
      {
        name: "import",
        type: "button",
        label: "Import"
      }
    
      ); }



    // all fields with data 
    this.regConfig = this.fieldsData;
    // attach to form
    this.form = DynamicFormComponent;
    // attach to formgrp to check for each element 
    this.formGrp = this.form;

    
  }

  // submit 
  submit(value: any) {

    delete value.save;
    delete value.delete;

    // // stop here if form is invalid
    if (this.form.invalid) {
      console.log("something isn't right")
      return;
    }

    let cdkTouched = 'cdk-touch-focused';
    let cdkFocused = 'cdk-focused';

    var touched = false;

    const target = event.target || event.srcElement || event.currentTarget;


    const element = event.currentTarget as HTMLInputElement

    //console.log("HTML xxxx:   " + target)

    // const x = element.value

    // loop all elements from the form and look for touched buttons 

    for (let i = 0; i < element.childElementCount; i++) {

      touched = element[i].classList.contains(cdkTouched) || element[i].classList.contains(cdkFocused);

      // console.log("Target : " + JSON.stringify(event.target))
      // console.log("I: " + i + " TOUCHED : " + touched)
      
      if (touched && element[i].innerText === "Copy") {
        console.log(" IF COPY IS PRESSED ********** ")
        this.newRecord(value)
        break;
      }

      if (touched && element[i].innerText === "Delete") {
        console.log(" IF DELETE IS PRESSED ********** ")
        this.deleteRecord()
        break;
      }

      if (touched && element[i].innerText === "Import") {
        console.log(" IF Import IS PRESSED ********** ")
        this.importFile()
        break;
      }


      else {

        if (touched && element[i].innerText === "Save") {
          // setTimeout(() => { async 
          if (this.search === 9999) {
            this.newRecord(value);
          } else {
            this.editRecord(value)
          }
       
        }
      }
    }

  }

  deleteRecord() {

    var tableName = this.table
    this._dataService.deleteTableRecord(this.search, tableName, this.KeyFieldName)
      .subscribe(async data => {
        //console.log("Deleted data: " + data);
        // this._invoiceID = data;
       // await this.router.navigate(['/reports']);
       this.location.back();
      },
        error => {
          console.log("something isn't right on submit return:" + error )
        }
      );
  }

  async newRecord(value) {
    var tableName = this.table
    await this._dataService.insertTableData(tableName, value)
      .subscribe(async data => {
       //console.log("submitted data: " + data);
       // await this.router.navigate(['/reports']);
       this.location.back();
      },
        error => {
          console.log("something isn't right on submit return :" + error)
        }
      );
  }

  editRecord(value) {

    // Edit 
    var tableName = this.table
    this._dataService.editTableData(this.search, tableName, value)
      .subscribe(async data => {
       // await console.log("submitted data: " + data);
        // this._invoiceID = data;
        // await this.router.navigate(['/reports']);
        this.location.back();
      },
        error => {
          console.log("something isn't right on submit return")

        }
        );
  }


  async importFile() {
    var tableName = this.table; 
    await this._dataService.importFile(this.search)
      .subscribe(async data => {
       //console.log("submitted data: " + data);
       // await this.router.navigate(['/reports']);
       this.location.back();
      },
        error => {
          console.log("something isn't right on submit return :" + error)
        }
      );
  }



  public ngOnDestroy(): void {

  //  this.subCall.unsubscribe();

  }


}

 
