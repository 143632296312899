import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output
} from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl
} from "@angular/forms";
import { FieldConfig, Validator } from "../../field.interface";

@Component({
  exportAs: "dynamicForm",
  selector: "dynamic-form",
  template: `
  <form class="dynamic-form" [formGroup]="form" (submit)="onSubmit($event)">
  <ng-container *ngFor="let field of fields;" dynamicField [field]="field"  [group]="form">
  </ng-container>
  </form>
  `,
  styles: []
})
export class DynamicFormComponent implements OnInit {
  @Input() fields: FieldConfig[] = [];

  @Output() submit: EventEmitter<any> = new EventEmitter<any>();

  form: FormGroup;

  get value() {
    return this.form.value;
  }
  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.form = this.createControl();
  }

  onSubmit(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.form.valid) {
      this.submit.emit(this.form.value);
    } else {
      this.validateAllFormFields(this.form);
    }
  }

  createControl() {
    const group = this.fb.group({});

      this.fields.forEach(field => {
        if (field.type === "button") return;
        const control = this.fb.control(
          field.value,
          this.bindValidations(field.validations || [])
        );
        group.addControl(field.name, control);
      });
      return group;
     
  }

  bindValidations(validations: any) {
    if (validations.length > 0) {

      const validList = [];

      for (let i = 0; i < validations.length; i++) {

        validations.forEach(valid => {

          if ( valid.name==="required"  ) { 
            validList.push(Validators.required);
          }

          if ( valid.name==="pattern" ) { 
            validList.push(Validators.pattern(valid.validator));
          }

          // validList.push(valid.validator);
        });
        return Validators.compose(validList);
      }
      return null;
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
     if (field!=="delete") {
      const control = formGroup.get(field);
      // var t = control.touched; 
      // console.log(field + " touched : " + t); 
      control.markAsTouched({ onlySelf: true });
    }
    });
  }

}
