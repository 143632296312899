import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import {
  Country,
  UsernameValidator,
  PasswordValidator,
  ParentErrorStateMatcher,
  PhoneValidator
} from '../validators';

import { DataService } from '../data-service/data.service';
// import { DataDetailListComponent } from '../data-detail-list/data-detail-list.component';

import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import { Router, ActivatedRoute } from '@angular/router';
import { isEmpty } from 'rxjs/operators';


// import 'jspdf-autotable';

@Component({
  selector: 'create-invoice',
  templateUrl: './create-invoice.component.html',
  styleUrls: ['./create-invoice.component.css']
})
export class CreateInvoiceComponent implements OnInit {

  returnUrl: string;

  public search: any;
  public seqnbr: any;

  data = [];
  public companyData: any = [];
  public customerData: any = [];
  public invoiceHeaderData: any = [];
  public invoiceDetailData: any = [];
  public itemData: any = [];
  public employeeData: any = [];
  public paymethodData: any = [];
  public i: any = 0;
  public _seqnbr: any = 0;
  public _invoiceTotals: any = 0;
  public _amountdue: any = 0;

  userDetailsForm: FormGroup;
  accountDetailsForm: FormGroup;
  matching_passwords_group: FormGroup;
  country_phone_group: FormGroup;
  countryform: FormGroup;

  submitted = false;

  selected: string;

  _invoiceID: any = [];
  _customerID: any = [];
  _customerName: any = [];
  _description: any = [];
  _notes: any = [];
  _itemNbr: any = [];
  _itemDesc: any = [];
  _employeeID: any = [];
  _employeeName: any = [];
  _hours: any = [];
  _price: any = [];
  _amountpaid: any = [];
  _paymentstatus: any;
  _paymethodID: any = [];
  _paymethodDesc: any = [];

  _selectedName: any = [];
  _selectedItemDesc: any = [];
  _selectedEmpName: any = [];
  _selectedPayMethodDesc: any = [];


  parentErrorStateMatcher = new ParentErrorStateMatcher();


  // var date = new FormControl(new Date());

  invoicedate = new FormControl(new Date()).value;

  // _invoicedate = new Date();



  element = document.getElementById("clickbind");

  // customer : string = 'SelectControl'



  genders = [
    "Male",
    "Female",
    "Other"
  ];

  countries = [
    new Country('UY', 'Uruguay'),
    new Country('US', 'United States'),
    new Country('AR', 'Argentina')
  ];


  validation_messages = {
    'description': [
      { type: 'required', message: 'Full name is required' }
    ],
    'notes': [
      { type: 'required', message: 'Full name is required' }
    ],
    'bio': [
      { type: 'maxlength', message: 'Bio cannot be more than 256 characters long' },
    ],
    'gender': [
      { type: 'required', message: 'Please select your gender' },
    ],
    'birthday': [
      { type: 'required', message: 'Please insert your birthday' },
    ],
    'phone': [
      { type: 'required', message: 'Phone is required' },
      { type: 'validCountryPhone', message: 'Phone incorrect for the country selected' }
    ]
  };

  account_validation_messages = {
    'username': [
      { type: 'required', message: 'Username is required' },
      { type: 'minlength', message: 'Username must be at least 5 characters long' },
      { type: 'maxlength', message: 'Username cannot be more than 25 characters long' },
      { type: 'pattern', message: 'Your username must contain only numbers and letters' },
      { type: 'validUsername', message: 'Your username has already been taken' }
    ],
    'email': [
      { type: 'required', message: 'Email is required' },
      { type: 'pattern', message: 'Enter a valid email' }
    ],
    'confirm_password': [
      { type: 'required', message: 'Confirm password is required' },
      { type: 'areEqual', message: 'Password mismatch' }
    ],
    'password': [
      { type: 'required', message: 'Password is required' },
      { type: 'minlength', message: 'Password must be at least 5 characters long' },
      { type: 'pattern', message: 'Your password must contain at least one uppercase, one lowercase, and one number' }
    ],
    'terms': [
      { type: 'pattern', message: 'You must accept terms and conditions' }
    ]
  }

  constructor(private fb: FormBuilder,
    private _dataService: DataService,
    private router: Router,
    private route: ActivatedRoute
    // ,private _detailList: DataDetailListComponent
  ) { }

  ngOnInit() {


    // this.accountDetailsForm.valueChanges.subscribe(console.log) ; 

    this._dataService.addHeaderRecord;
    this._dataService.editHeaderRecord;
    this._dataService.addDetailRecord;
    this._dataService.editDetailRecord;
    this._dataService._screenParameter;

    this.getDataforForms();
    this.populateForms();

  }

  getDataforForms() {

    this.search = parseInt(this.route.snapshot.paramMap.get('search'));


    // New record _______________________________________________________
    if (this.search === 0) {

      this.createHeaderRecord();

    } else {

      // update record --------------------------------------------------

      if (this._dataService.editHeaderRecord) {
        this.editHeaderRecord();
      };

      if (this._dataService.addDetailRecord) {
        this.createDetailRecord();
        this.editHeaderRecord();
      };

      if (this._dataService.editDetailRecord) {
        this.editDetailRecord();
        this.editHeaderRecord();
      };

    }

  }


  createHeaderRecord() {

    this._dataService.getCustomerList(this.search).subscribe(data => {
      this.customerData = data;
      this._customerID = data.map(res => res.CTCustomerID);
      this._customerName = data.map(res => res.CTCustomerName);
      this._selectedName = this._customerName[0];
      console.log(JSON.stringify(this.customerData[0]));
    });

    this._hours[0] = 0;
    this._price[0] = 0;
    this._itemNbr[0] = ' ';
    this._selectedItemDesc = ' ';
    this._employeeID[0] = ' ';
    this._selectedEmpName = ' ';
    this._paymethodID = ' ';
    this._selectedPayMethodDesc = ' ';
    this._amountpaid[0] = 0;

    // this._dataService.getPayMethod(search).subscribe(data => {
    //   this.paymethodData = data;
    //   this._paymethodID = this.paymethodData.map(res => res.paymethod_id);
    //   this._paymethodDesc = this.paymethodData.map(res => res.paymethoddesc);
    //   this._selectedPayMethodDesc = this._paymethodDesc[0];
    //   console.log(this._selectedPayMethodDesc[0]);
    // });

  }


  editHeaderRecord() {

    this._hours[0] = 0;
    this._price[0] = 0;
    this._itemNbr[0] = ' ';
    this._selectedItemDesc = ' ';
    this._employeeID[0] = ' ';
    this._selectedEmpName = ' ';
    this._paymethodID = ' ';
    this._selectedPayMethodDesc = ' ';
    this._amountpaid[0] = 0;

    // customer 
    this._dataService.getInvoiceHeader(this.search).subscribe(data => {
      this.invoiceHeaderData = data;
      this._customerID = data.map(res => res.IHCustomerID);
      this._customerName = data.map(res => res.IHCustomerName);
      this._selectedName = this._customerName[0];
      console.log("CUSTOMER :" + this._selectedName);

      // this.accountDetailsForm = this.fb.group({
      //   customerName: []
      // });

      this._dataService.getCustomerList(this._customerID[0]).subscribe(data => {
        this.customerData = data;
        console.log("CUSTOMER DATA :" + JSON.stringify(this.customerData));
      })


      // invoice description
      this._description = (data.map(res => res.IHInvDescription));
      console.log("DESCRIPTION :" + this._description);

      // invoice notes
      this._notes = (data.map(res => res.IHOfficeNote));
      console.log("NOTES :" + this._notes);

      // invoice date
      this.invoicedate = (data.map(res => res.IHInvoiceDate));
      // this.invoicedate = "2/1/2016";
      this.invoicedate = new FormControl(new Date(this.invoicedate)).value;
      console.log("DATE :" + this.invoicedate);


      // this.customerData.CTCustomerID= (data.map(res => res.IHCustomerID));
      this._paymentstatus = (data.map(res => res.IHStatus));
      // this._customer =  this.customerData.CTCustomerName;
      this._invoiceID = (data.map(res => res.IHInvoiceID));

      // amount paid
      this._amountpaid[0] = (data.map(res => res.IHAmountPaid));
      console.log("Amount Paid :" + this._price[0]);

      // payment method from invoice header
      this._selectedPayMethodDesc = data.map(res => res.IHPaymentType);

      this._dataService.getPayMethodData('').subscribe(data => {
        this.paymethodData = data;
        this._paymethodID = this.paymethodData.map(res => res.paymethod_id);
        // this._paymethodDesc = this.paymethodData.map(res => res.paymethoddesc);
        this._paymethodID[0] = this.getPayMethodID(this._selectedPayMethodDesc[0]);
        // this._selectedPayMethodDesc = this._paymethodDesc[0];
        console.log(this._selectedPayMethodDesc);
      });

    })

    this.getInvoiceTotals()
    // invoice totals  
    // this._dataService.getInvoiceTotals(this.search).subscribe(data => {
    //   this.invoiceHeaderData = data;
    //   this._invoiceTotals = data.map(res => res.invoicetotals);

    // })

  }

  createDetailRecord() {

    //item
    this._dataService.getItem('').subscribe(data => {
      this.itemData = data;
      this._itemNbr[0] = this.getItemNbr(this._selectedItemDesc);
      // this._itemNbr = this.itemData.map(res => res.IMItmNbr);
      this._itemDesc = this.itemData.map(res => res.IMItmDes);
      this._selectedItemDesc = this._itemDesc[0];
      console.log("ITEM :" + this._selectedItemDesc);
    });

    this._dataService.getEmployee('').subscribe(data => {
      this.employeeData = data;
      this._employeeID[0] = this.getEmpID(this._employeeName);
      // this._employeeID = this.employeeData.map(res => res.TTTechID);
      this._employeeName = this.employeeData.map(res => res.TTTechName);
      this._selectedEmpName = this._employeeName[0];
      console.log("EMPOLYEE :" + this._selectedEmpName);
    });


    // price
    // this._price[0] = (data.map(res => res.IDItemPrice));
    console.log("PRICE :" + this._price[0]);

    //qty
    // this._hours[0] = (data.map(res => res.IDQty));
    console.log("QTY/HOURS :" + this._hours[0]);

    this.getInvoiceTotals();

  }

  editDetailRecord() {

    // Seq / Line # 
    this._seqnbr = this._dataService._screenParameter;

    this._dataService.getRecordDetail(this.search, this._seqnbr).subscribe(data => {
      this.invoiceDetailData = data;


      // item description from invoice detail 
      this._selectedItemDesc = (data.map(res => res.IDItemDescription));

      this._dataService.getItem('').subscribe(data => {
        this.itemData = data;
        this._itemDesc = this.itemData.map(res => res.IMItmDes);
        this._itemNbr[0] = this.getItemNbr(this._selectedItemDesc);
        console.log("ITEM :" + this._selectedItemDesc);
      });


      // employee
      // this.employeeData = data;
      this._selectedEmpName = (data.map(res => res.IDEmpNam));

      this._dataService.getEmployee('').subscribe(data => {
        this.employeeData = data;
        this._employeeID[0] = this.getEmpID(this._selectedEmpName);
        this._employeeName = this.employeeData.map(res => res.TTTechName);
        console.log("EMPOLYEE :" + this._selectedEmpName);
      });


      // price
      this._price[0] = (data.map(res => res.IDItemPrice));
      console.log("PRICE :" + this._price[0]);

      //qty
      this._hours[0] = (data.map(res => res.IDQty));
      console.log("QTY/HOURS :" + this._hours[0]);
    })

  }


  populateForms() {

    // form validations
    this.accountDetailsForm = this.fb.group({
      invoice: new FormControl(this._invoiceID[0]),
      invoicetotals: new FormControl(this._invoiceTotals[0]),
      amountdue: new FormControl(this._amountdue[0]),
      paymentstatus: new FormControl(this._paymentstatus),
      customer: new FormControl(this._customerID[0], Validators.compose([
      ])),
      customerName: new FormControl(this._selectedName[0], Validators.compose([
        // UsernameValidator.validUsername,
        // Validators.maxLength(25),
        // Validators.minLength(5),
        Validators.required,
        // Validators.pattern('^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]+$')
      ])),
      description: new FormControl(this._description, Validators.compose([
        Validators.required,
        // Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])), 
      invoicedate: new FormControl(this.invoicedate, Validators.compose([
        Validators.required
      ])),
      notes: new FormControl(this._notes, Validators.compose([
        Validators.required,
        // Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])),
      item: new FormControl(this._itemNbr[0], Validators.compose([
        Validators.required,
        // Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])),
      itemDesc: new FormControl(this._selectedItemDesc[0], Validators.compose([
        Validators.required,
        // Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])),
      employee: new FormControl(this._employeeID[0], Validators.compose([
        Validators.required,
        // Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])),
      employeeName: new FormControl(this._selectedEmpName[0], Validators.compose([
        Validators.required,
        // Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])),
      hours: new FormControl(this._hours[0], Validators.compose([
        Validators.required,
        Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$')
      ])),
      price: new FormControl(this._price[0], Validators.compose([
        Validators.required,
        Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$')
      ])),
      amountpaid: new FormControl(this._amountpaid[0], Validators.compose([
        Validators.required,
        Validators.pattern('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$')
      ])),
      paymethod: new FormControl(this._paymethodID[0], Validators.compose([
        Validators.required,
        // Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])),
      paymethoddesc: new FormControl(this._selectedPayMethodDesc[0], Validators.compose([
        Validators.required,
        // Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])),
      // matching_passwords: this.matching_passwords_group,
      // terms: new FormControl(false, Validators.pattern('true'))
    })



    // userEdit(user, i) {
    //   this.form.setValue({
    //     index: i,
    //     username: user.username,
    //     email: user.email
    //   })
    // }



    // let phone = new FormControl('', {
    //   validators: Validators.compose([
    //     Validators.required,
    //     PhoneValidator.validCountryPhone(country)
    //   ])
    // });

    // this.country_phone_group = new FormGroup({
    //   country: country,
    //   phone: phone
    // });

  }


  onSubmitAccountDetails(value) {
    console.log("FORM VALUES: " + value);

    // // stop here if form is invalid
    if (this.accountDetailsForm.invalid) {
      console.log("something isn't right")
      return;
    }


    if (this.submitted === false) {

      if (this._dataService.addHeaderRecord) {

        this._dataService.insertHeaderData(value)
          .subscribe(
            data => {
              console.log("submitted data: " + data);
              this._invoiceID = data;
              this.router.navigate(['/dataList', this._invoiceID]);
            },
            error => {

              console.log("something isn't right on submit return")

            }
          );


      }


      // this.deleteInvoiceDetailRecord();


      // insert invoice detail and update header totals 

      if (this._dataService.addDetailRecord) {

        this._dataService.insertDetailData(value)
          .subscribe(
            data => {
              //    console.log("submitted data: " + data);
              //    this.router.navigate(['/dataList']);

              this.getInvoiceTotals();

              this._dataService.editHeaderData(value)
                .subscribe(
                  data => {
                    //    console.log("submitted data: " + data);
                    //  this.router.navigate(['/dataList']);
                  },
                  error => {

                    console.log("something isn't right on submit return")

                  }
                );

              this.router.navigate(['/dataList', data]);
            },
            error => {

              console.log("something isn't right on submit return")

            }
          );

      }

      else {


        if (this._dataService.editHeaderRecord) {

          this._dataService.editHeaderData(value)
            .subscribe(
              data => {
                //    console.log("submitted data: " + data);
                this.router.navigate(['/dataList']);
              },
              error => {

                console.log("something isn't right on submit return")

              }
            );

        }



        if (this._dataService.editDetailRecord) {

          this._dataService.editDetailData(value, this._seqnbr)
            .subscribe(
              data => {
                console.log("submitted data: " + data);

                // this.router.navigate(['/dataList', data]);
              },
              error => {

                console.log("something isn't right on submit return")

              }
            );

        }

        this.getInvoiceTotals();

        if (!this.invoiceHeaderData[0].invoicetotals == null) {
          this.accountDetailsForm.patchValue({
            invoicetotals: (this.invoiceHeaderData[0].invoicetotals)
          })
        }

        this._dataService.editHeaderData(value)
          .subscribe(
            data => {
              console.log("submitted data: " + data);
              // this.router.navigate(['/dataList', data]);
            },
            error => {

              console.log("something isn't right on submit return")

            }
          );

        this.submitted = true;


      }
    }

  }

  deleteInvoiceDetailRecord() {

    this._dataService.deleteInvoiceDetailRecord(this.search, this._seqnbr)
      .subscribe(async data => {
        console.log("Deleted data: " + data);
        // this._invoiceID = data;
        this.router.navigate(['/dataList', data]);
      },
        error => {
          console.log("something isn't right on submit return")
        }
      );
  }

  get invoiceNbr() {
    return this._invoiceID = (this.invoiceHeaderData.map(res => res.IHInvoiceID));
  }


  get hours() {
    return this.accountDetailsForm.get('hours')
  }

  get price() {
    return this.accountDetailsForm.get('price')
  }

  get invoicelinetotals() {
    return (this.hours.value * this.price.value);
  }

  get invoicetotals() {
    return (this._invoiceTotals[0]);
  }

  get newinvoicetotals() {

    return (this._invoiceTotals + this.invoicelinetotals);
  }

  get amountdue() {
    return (this.invoicetotals - this._amountpaid);
  }

  get paymentstatus() {

    if (this.invoicetotals == 0 || this.invoicetotals == null) { return ('New') }
    else {
      if (this.amountdue > 0) { return ('Pending') }
      else {
        if (this.amountdue < 0) { return ('Over Paid') }
        else {
          if (this.amountdue == 0) { return ('Paid') }
          return (' ');
        }
      }
    }
  }


  // invoice totals
  getInvoiceTotals() {
    this._dataService.getInvoiceTotals(this.search).subscribe(data => {
      this.invoiceHeaderData = data;
      this._invoiceTotals = data.map(res => res.invoicetotals);
      if (this._invoiceTotals == null) this._amountdue._invoiceTotals = 0;
    })
  }

  getCustomerName(i): any {
    this._selectedName = this.customerData.find(data => data.CTCustomerID == i).CTCustomerName;
    console.log(" Name from function  : " + this._selectedName);
    return this._selectedName;
  }

  getItemDesc(i): any {
    this._selectedItemDesc = this.itemData.find(data => data.IMItmNbr == i).IMItmDes;
    console.log(" Name from function  : " + this._selectedItemDesc);
    return this._selectedItemDesc;
  }

  getItemNbr(i): any {
    this._itemNbr[0] = this.itemData.find(data => data.IMItmDes == i).IMItmNbr;
    console.log(" Name from function  : " + this._itemNbr);
    return this._itemNbr[0];
  }

  getEmpName(i): any {
    this._selectedEmpName = this.employeeData.find(data => data.TTTechID == i).TTTechName;
    console.log(" Name from function  : " + this._selectedEmpName);
    return this._selectedEmpName;
  }

  getEmpID(i): any {
    this._employeeID[0] = this.employeeData.find(data => data.TTTechName == i).TTTechID;
    console.log(" ID from function empID: " + this._employeeID);
    return this._employeeID[0];
  }

  getPayMethodDesc(i): any {
    this._selectedPayMethodDesc = this.paymethodData.find(data => data.paymethod_id == i).paymethoddesc;
    console.log(" Name from function  : " + this._selectedPayMethodDesc);
    return this._selectedPayMethodDesc;
  }

  getPayMethodID(i): any {
    // if (!i.isEmpty) 
    // {
    this._paymethodID[0] = this.paymethodData.find(data => data.paymethoddesc == i).paymethod_id;
    // }
    console.log(" pay ID from function  : " + this._paymethodID);
    return this._paymethodID[0];
  }

  get employee() {
    return this.accountDetailsForm.get('employee')
  }

}


