import { ErrorHandler, Injectable, Injector } from "@angular/core";
import { Router } from "@angular/router";

@Injectable({
  // providedIn: 'user'
  providedIn: "root",
})
export class AuthErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error) {
    console.log("errrrrrr:" + JSON.parse(error));
    const router = this.injector.get(Router);
    if (error.rejection.status === 401 || error.rejection.status === 403) {
      router.navigate(["/login"]);
    }

    throw error;
  }
}

// src/app/auth/jwt.interceptor.ts
// ...
// import {
//     HttpRequest,
//     HttpHandler,
//     HttpEvent,
//     HttpInterceptor, HttpResponse, HttpErrorResponse
// } from '@angular/common/http';
// import { Injectable, Injector } from '@angular/core';

// import { AuthenticationService } from '../_services';
// import 'rxjs/add/operator/do';

// import { Observable } from 'rxjs';

// export class JwtInterceptor implements HttpInterceptor {
//     constructor(public auth: AuthenticationService) { }

//     intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

//         return next.handle(request).do((event: HttpEvent<any>) => {
//             if (event instanceof HttpResponse) {
//                 // do stuff with response if you want
//             }
//         }, (err: any) => {
//             if (err instanceof HttpErrorResponse) {
//                 if (err.status === 401) {
//                     // redirect to the login route
//                     // or show a modal
//                 }
//             }
//         });
//     }
// }
