import { Injectable } from '@angular/core';

// import { CounterComponent } from '../counter/counter.component';
// import { FetchDataComponent } from '../fetch-data/fetch-data.component';
// import { HomeComponent } from '../home/home.component';

import { DataDetailListComponent } from './../data-detail-list/data-detail-list.component';
import { ReportsListComponent } from './../reports/report-list.component'
import { ScannedFormsComponent } from './../scannedforms/scannedforms.component'
import { DataDetailComponent } from './../data-detail/data-detail.component'; 

import { TabPaneItem } from './tab-pane-item';

@Injectable()
export class TabPanesService {
  public getTabPanes(): TabPaneItem[] {
    return [
      // new TabPaneItem(CounterComponent),
      // new TabPaneItem(FetchDataComponent),
      // new TabPaneItem(HomeComponent)

      // new TabPaneItem(DataDetailComponent),
      new TabPaneItem(DataDetailListComponent),
      new TabPaneItem(ScannedFormsComponent),
      new TabPaneItem(ReportsListComponent),

      


    ];
  }
}
