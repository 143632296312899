import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login';
import { AuthGuard, AuthLogin } from './_guards';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CreateInvoiceComponent } from './create-invoice/create-invoice.component';
import { DataListComponent } from './data-list/data-list.component'
import { DataDetailComponent } from './data-detail/data-detail.component';
import { DataDetailListComponent } from './data-detail-list/data-detail-list.component';
import { ScannedFormsComponent } from './scannedforms/scannedforms.component';
import { ListViewComponent } from './list-view/list-view.component';
import { RecordPanelComponent } from './record-panel/record-panel.component';
import { ReportViewComponent } from './report-view/report-view.component';
import { PrintViewComponent } from './print-view/print-view.component' ;

// import { AuthLogin } from './_guards';
// import { HomeComponent } from './home';
// import { RegisterComponent } from './register';
// import { AuthGuard } from './_guards';
 

const appRoutes: Routes = [
  
    { path: '', component: DataListComponent, canActivate: [AuthLogin], pathMatch: 'full'},
    { path: 'login', component: LoginComponent },
    { path: 'listview/:search', component: ListViewComponent, canActivate: [AuthLogin]  },
    { path: 'recordpanel/:search', component: RecordPanelComponent, canActivate: [AuthLogin]},
    { path: 'reportview/:search', component: ReportViewComponent, canActivate: [AuthLogin]  },
    { path: 'dashboard/:search', component: DashboardComponent, canActivate: [AuthGuard] },
    { path: 'dataList', component: DataListComponent, canActivate: [AuthLogin] },
    { path: 'dataList/:search', component: DataDetailComponent, canActivate: [AuthLogin]  },
    { path: 'detaillist', component: DataDetailListComponent, canActivate: [AuthLogin]  },
    { path: 'createinvoice/:search', component: CreateInvoiceComponent, canActivate: [AuthLogin]  },
    { path: 'createinvoice/:search/:line', component: CreateInvoiceComponent, canActivate: [AuthLogin]  },
    { path: 'printview/:search', component: PrintViewComponent, canActivate: [AuthLogin]  },
    { path: 'scannedforms', component: ScannedFormsComponent, canActivate: [AuthLogin]  },
   
    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

export const routing = RouterModule.forRoot(appRoutes);
// export const routing = RouterModule.forRoot(appRoutes,{useHash:true});