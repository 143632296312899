import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FieldConfig } from "../../field.interface";
@Component({
  selector: "app-button",
  template: `
    <div class="demo-full-width margin-top" [formGroup]="group">
      <button type="submit" mat-raised-button color="primary">
        {{ field.label }}
      </button>
    </div>
  `,

  // template: `
  //   <div class="container">
  //     t
  //     <div class="row">
  //       <div class="col-12">
  //         r
  //         <table class="table table-bordered">
  //           <tr>
  //             x
  //             <div class="demo-full-width margin-top" [formGroup]="group">
  //               <button type="submit" mat-raised-button color="primary">
  //                 {{ field.label }}
  //               </button>
  //             </div>
  //           </tr>
  //         </table>
  //       </div>
  //     </div>
  //   </div>
  // `,

  styles: [],
})
export class ButtonComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  constructor() {}
  ngOnInit() {}
}
