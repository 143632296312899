import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
// import { Router } from './../app.routing' 
import { Router } from '@angular/router';
import { User } from './../_models';
import { Observable } from 'rxjs';
import { DataService } from '../data-service/data.service';


@Injectable()
export class AuthenticationService {

    constructor(private http: HttpClient,
        private router: Router,
        private _dataService: DataService) { }

    // apiUrl = 'http://172.16.16.61:3003/api';

    // test 
    apiUrl = 'http://localhost:3003/api';

    // isFullAccess: boolean ;  

    login(username: string, password: string) {


        return this.http.post<any>(`${this.apiUrl}/users/login`, { username: username, password: password })

            // return this.http.post(`${this.apiUrl}/users/authenticate`, { params })
            .pipe(map(user => {


                if (user) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes


                    let currentUser = JSON.stringify(user["data"]);
                    let token = JSON.stringify(user["token"]);
                    token = token.replace('"', '')
                    token = token.replace('"', '')


                    localStorage.setItem('currentUser', currentUser);
                    localStorage.setItem('token', token)
                }

                this.isFullAccess();


                return user;
            }));
    }



    isFullAccess(): boolean {

        var curUser = JSON.parse(localStorage.getItem('currentUser'));

        // console.log("curUser: " + JSON.stringify(curUser) + " useraccess: " + JSON.stringify(curUser[0]["useraccess"].trim()))

        // if (curUser != null && curUser[0]["useraccess"].trim() == 'full') return true;
        return true;

    }


    getToken() {

        return localStorage.getItem('token');

    }


    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        localStorage.removeItem('token');
        // clear search shared value before logging off 
        this._dataService.changesearchValue("");

    }
}