import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, tap, catchError } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';
import { Response, URLSearchParams, RequestOptions } from '@angular/http';
import { async } from 'q';

export interface Modes {
  addHeaderRecord: boolean;
  editHeaderRecord: boolean;
  addDetailRecord: boolean;
  editDetailRecord: boolean;
  _screenParameter: any;
}

@Injectable()
export class DataService {

  // for local testing
  apiUrl = 'http://localhost:3003/api';

  // live
  // apiUrl = 'http://172.16.16.61:3003/api'; 


  tablename: string;
  apppage: string;
  apppageview: string;

  addHeaderRecord = false;
  editHeaderRecord = false;
  addDetailRecord = false;
  editDetailRecord = false;
  _screenParameter = 0;

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'my-auth-token'
    })
  };

  // private options = new RequestOptions();

  constructor(private http: HttpClient) { }


  // sharing variable value between components
  private sharingSource = new BehaviorSubject<string>("default wasnbr");
  currentMessage = this.sharingSource.asObservable();

  // changevalue when this function is called
  changeValue(sharevariablevalue: string) {
    this.sharingSource.next(sharevariablevalue)
  }


  // sharing variable value between components
  private sharingSearch = new BehaviorSubject<string>("");
  currentSearch = this.sharingSearch.asObservable();

  // changevalue when this function is called
  changesearchValue(sharedsearchvalue: string) {
    this.sharingSearch.next(sharedsearchvalue)
  }


    // sharing variable value between components
    private sharingParms = new BehaviorSubject<string>("");
    currentParms = this.sharingParms.asObservable();
  
    // changevalue when this function is called
    changeParmsValue(sharedParmsValue: string) {
    this.sharingParms.next(sharedParmsValue)
    }
  
  // get all data 
  getData(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiUrl}/getdata`);
  }

  // get search data 
  getSearch(search: any): Observable<any[]> {

    //let params: URLSearchParams = new URLSearchParams();
    let params = new HttpParams().set("search", search);
    // console.log(this.httpOptions); 
    console.log("paramsSearch : " + search);
    return this.http.get<any[]>(`${this.apiUrl}/search`, { params });
  }

  // get all data 
  getAdminData(search: any): Observable<any[]> {
    let params = new HttpParams().set("search", search);
    return this.http.get<any[]>(`${this.apiUrl}/getadmindata`, { params });
  }

  insertAdminData(values: any): Observable<any[]> {
    console.log("insert appcontrol: DATASERVICE : " + JSON.stringify(values));
    return this.http.post<any>(`${this.apiUrl}/insertadmindata`, { values })
      .pipe(map(_returndata => {
        // if (_returndata) {

        //   let _data = JSON.stringify(_returndata["data"]);

        //   console.log(_data);

        // }

        // this.isFullAccess();

        return _returndata
      }));
  }

  editAdminData(search: any, values: any): Observable<any[]> {
    let params = new HttpParams().append("search", search);
    console.log("update admin data : DATASERVICE : " + JSON.stringify(values));
    return this.http.put<any>(`${this.apiUrl}/editadmindata`, { values, params })
      .pipe(map(_returndata => {

        // if (_returndata) {

        //   let _data = JSON.stringify(_returndata["data"]);

        //   console.log(_data);

        // }

        // this.isFullAccess();

        return _returndata
      }));
  }

  deleteAdminRecord(search: any): Observable<any[]> {
  

    let params = new HttpParams().append("search", search);

    // console.log("Delete  data : DATASERVICE : " + JSON.stringify(values));
    return this.http.delete<any>(`${this.apiUrl}/deleteadminrecord`, { params })
      .pipe(map(_returndata => {
        async


        // if (_returndata) {

        //   let _data = JSON.stringify(_returndata["data"]);

        //   console.log(_data);

        // }

        // this.isFullAccess();

        return _returndata
      }));
  }

  deleteInvoiceDetailRecord(search: any, seqnbr: any): Observable<any[]> {
    async

    let params = new HttpParams().append("search", search);
    params = params.append("seqnbr", seqnbr);

    // console.log("Delete  data : DATASERVICE : " + JSON.stringify(values));
    return this.http.delete<any>(`${this.apiUrl}/deleteinvoicedetailrecord`, { params })
      .pipe(map(_returndata => {
        async


        // if (_returndata) {

        //   let _data = JSON.stringify(_returndata["data"]);

        //   console.log(_data);

        // }

        // this.isFullAccess();

        return _returndata
      }));
  }

  // get field data 
  getFields(search: any, id_Field: any, tableName: any, fieldName: any): Observable<any[]> {
    let params = new HttpParams().set("search", search);
    params = params.append("id_field", id_Field);
    params = params.append("tablename", tableName);
    params = params.append("fieldname", fieldName);
    return this.http.get<any[]>(`${this.apiUrl}/getfields`, { params });
  }


  // get field validations  data 
  getFieldValidations(search: any): Observable<any[]> {
    let params = new HttpParams().set("search", search);
    return this.http.get<any[]>(`${this.apiUrl}/getfieldvalidations`, { params });
  }

  getInvoiceHeader(search: any): Observable<any[]> {

    let params = new HttpParams().set("search", search);


    return this.http.get<any[]>(`${this.apiUrl}/getinvoiceheader`, { params });

  }

  getDetail(search: any): Observable<any[]> {

    let params = new HttpParams().set("search", search);


    return this.http.get<any[]>(`${this.apiUrl}/getdetail`, { params });

  }

  getRecordDetail(search: any, seqnbr: any): Observable<any[]> {

    this.tablename = 'invoice';
    this.apppage = 'detailrecord';

    let params = new HttpParams().append("search", search);
    params = params.append("seqnbr", seqnbr);
    params = params.append("tablename", this.tablename);
    params = params.append("apppage", this.apppage);

    return this.http.get<any[]>(`${this.apiUrl}/getrecorddetail`, { params });
  }


  getCompanyDetail(search: any): Observable<any[]> {

    let params = new HttpParams().set("search", search);

    return this.http.get<any[]>(`${this.apiUrl}/getcompanydetail`, { params });
  }

  getCustomerList(search: any): Observable<any[]> {

    // let params = new HttpParams().set("search", search);

    return this.http.get<any[]>(`${this.apiUrl}/getcustomerlist`);
  }

  getItem(search: any): Observable<any[]> {

    // let params = new HttpParams().set("search", search);

    return this.http.get<any[]>(`${this.apiUrl}/getitem`);
  }

  getReportData(search: any): Observable<any[]> {

    this.tablename = search;
    //this.apppage = 'report';

    let params = new HttpParams().append("search", search);
   // params = params.append("tablename", this.tablename);
   // params = params.append("apppage", this.apppage);

    // params = params.append(k, params[k]);

    return this.http.get<any[]>(`${this.apiUrl}/getreportdata`, { params });
  }

  getTableList(search: any): Observable<any[]> {

    this.tablename = search;
    this.apppage = 'list';

    let params = new HttpParams().append("search", search);
    params = params.append("tablename", this.tablename);
    params = params.append("apppage", this.apppage);

    // params = params.append(k, params[k]);

    return this.http.get<any[]>(`${this.apiUrl}/gettablelist`, { params });
  }


  getTableListByField(search: any, fieldName: any): Observable<any[]> {

    this.tablename = search;
    this.apppage = fieldName;

    let params = new HttpParams().append("search", search);
    params = params.append("tablename", this.tablename);
    params = params.append("apppage", this.apppage);

    // params = params.append(k, params[k]);

    return this.http.get<any[]>(`${this.apiUrl}/gettablelist`, { params });
  }

  getTableRecord(search: any, tableName: any): Observable<any[]> {

    this.tablename = tableName;
    this.apppage = 'record';

    let params = new HttpParams().append("search", search);
    params = params.append("tablename", this.tablename);
    params = params.append("apppage", this.apppage);
    return this.http.get<any[]>(`${this.apiUrl}/gettablerecord`, { params });
  }

  insertTableData(tableName: any, values: any): Observable<any[]> {

    let params = new HttpParams().append("tablename", tableName);
    console.log("insert Table DATA: DATASERVICE : " + JSON.stringify(values));
    return this.http.post<any>(`${this.apiUrl}/inserttabledata`, { values, params })
      .pipe(map(_returndata => {
        return _returndata
      }));
  }

  editTableData(search: any, tableName: any, values: any): Observable<any[]> {

    let params = new HttpParams().append("search", search);
    params = params.append("tablename", tableName);

    console.log("update admin data : DATASERVICE : " + JSON.stringify(values));
    return this.http.put<any>(`${this.apiUrl}/edittabledata`, { values, params })
      .pipe(map(_returndata => {


        // if (_returndata) {

        //   let _data = JSON.stringify(_returndata["data"]);

        //   console.log(_data);

        // }

        // this.isFullAccess();

        return _returndata
      }));
  }

  deleteTableRecord(search: any, tableName: any, keyFieldName: any): Observable<any[]> {

    let params = new HttpParams().append("search", search);
    params = params.append("tablename", tableName);
    params = params.append("keyFieldName", keyFieldName);
    // console.log("Delete  data : DATASERVICE : " + JSON.stringify(values));

    return this.http.delete<any>(`${this.apiUrl}/deletetablerecord`, { params })
      .pipe(map(_returndata => {
        return _returndata
      }));
  }

  importFile(search: any): Observable<any[]> {
    let params = new HttpParams().append("search", search);
    return this.http.get<any[]>(`${this.apiUrl}/importfile`, {params});
  }

  getEmployee(search: any): Observable<any[]> {

    this.tablename = 'employee';
    this.apppage = 'list';

    let params = new HttpParams().append("search", search);
    params = params.append("tablename", this.tablename);
    params = params.append("apppage", this.apppage);

    // params = params.append(k, params[k]);

    return this.http.get<any[]>(`${this.apiUrl}/gettablelist`, { params });
  }

  getPayMethodData(search: any): Observable<any[]> {

    this.tablename = 'paymentmethod';
    this.apppage = 'list';

    let params = new HttpParams().append("search", search);
    params = params.append("tablename", this.tablename);
    params = params.append("apppage", this.apppage);



    // params = params.append(k, params[k]);

    return this.http.get<any[]>(`${this.apiUrl}/gettablelist`, { params });
  }

  getInvoiceTotals(search: any): Observable<any[]> {

    this.tablename = 'invoice';
    this.apppage = 'totals';

    let params = new HttpParams().append("search", search);
    params = params.append("tablename", this.tablename);
    params = params.append("apppage", this.apppage);

    return this.http.get<any[]>(`${this.apiUrl}/gettotals`, { params });
  }


  getCustomerDetail(search: any): Observable<any[]> {

    let params = new HttpParams().set("search", search);

    return this.http.get<any[]>(`${this.apiUrl}/getcustomerdetail`, { params });
  }


  insertHeaderData(values: any): Observable<any[]> {

    // let params = new HttpParams().set("search", search);


    // return this.http.get<any[]>(`${this.apiUrl}/getdetail`, { params });

    // login(username: string, password: string) {

    console.log("create invoice: DATASERVICE : " + JSON.stringify(values));
    return this.http.post<any>(`${this.apiUrl}/insertheaderdata`, { values: values })

      // return this.http.post(`${this.apiUrl}/users/authenticate`, { params })
      .pipe(map(invoice => {


        if (invoice) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes


          let invoicedata = JSON.stringify(invoice["data"]);

          console.log(invoicedata);


          // let token = JSON.stringify(user["token"]);
          // token = token.replace('"', '')
          // token = token.replace('"', '')


          // localStorage.setItem('currentUser', currentUser);
          // localStorage.setItem('token', token)
        }

        // this.isFullAccess();


        return invoice
      }));
  }

  editHeaderData(values: any): Observable<any[]> {

    this.tablename = 'invoice';
    this.apppage = 'updateinvoiceheader';

    let params = new HttpParams();
    params = params.append("tablename", this.tablename);
    params = params.append("apppage", this.apppage);

    console.log("update invoice: DATASERVICE : " + JSON.stringify(values));
    return this.http.put<any>(`${this.apiUrl}/updateheaderdata`, { values, params })
      .pipe(map(invoice => {


        if (invoice) {

          let invoicedata = JSON.stringify(invoice["data"]);

          console.log(invoicedata);

        }

        // this.isFullAccess();


        return invoice
      }));
  }


  insertDetailData(values: any): Observable<any[]> {

    this.tablename = 'invoice';
    this.apppage = 'createinvoicedetail';

    let params = new HttpParams();
    params = params.append("tablename", this.tablename);
    params = params.append("apppage", this.apppage);

    console.log("update invoice: DATASERVICE : " + JSON.stringify(values));
    return this.http.post<any>(`${this.apiUrl}/insertdetaildata`, { values, params })
      .pipe(map(invoice => {


        if (invoice) {

          let invoicedata = JSON.stringify(invoice["data"]);

          console.log(invoicedata);

        }

        // this.isFullAccess();


        return invoice
      }));
  }

  // editDetailData(values: any): Observable<any[]> {

  //   this.tablename = 'invoice';
  //   this.apppage = 'updateinvoicedetail';

  //   let params = new HttpParams();
  //   params = params.append("tablename", this.tablename);
  //   params = params.append("apppage", this.apppage);

  //   console.log("update invoice: DATASERVICE : " + JSON.stringify(values));
  //   return this.http.put<any>(`${this.apiUrl}/updatedetaildata`, { values, params })
  //     .pipe(map(invoice => {


  //       if (invoice) {

  //         let invoicedata = JSON.stringify(invoice["data"]);

  //         console.log(invoicedata);

  //       }

  //       // this.isFullAccess();


  //       return invoice
  //     }));
  // }

  editDetailData(values: any, seqnbr: any): Observable<any[]> {

    this.tablename = 'invoice';
    this.apppage = 'updatedetailrecord';

    let params = new HttpParams();
    params = params.append("seqnbr", seqnbr);
    params = params.append("tablename", this.tablename);
    params = params.append("apppage", this.apppage);

    return this.http.put<any[]>(`${this.apiUrl}/updatedetaildata`, { values, params })
      .pipe(map(invoice => {

        if (invoice) {

          let invoicedata = JSON.stringify(invoice["data"]);

          console.log(invoicedata);

        }

        return invoice
      }));
  }



  getReports(): Observable<any[]> {

    // console.log("reports : "); 

    return this.http.get<any[]>(`${this.apiUrl}/getreports`);

  }


  // Get all scanned forms for the selected Musi

  getScannedForms(search: any): Observable<any[]> {

    let params = new HttpParams().set("search", search);

    // console.log(this.httpOptions); 
    console.log("paramsSearch : " + search);

    return this.http.get<any[]>(`${this.apiUrl}/getscannedforms`, { params });

  }

  // get detail screen data 
  getDetails(search: any): Observable<any[]> {

    let params = new HttpParams().set("search", search);

    // console.log(this.httpOptions); 
    console.log("paramsSearch : " + search);

    return this.http.get<any[]>(`${this.apiUrl}/getdetails`, { params });

  }


  // get chart data 
  getChartData(data: any): Observable<any[]> {

    this.tablename = data.appmodule;
    this.apppage = data.apppage;
    this.apppageview = data.apppageview;

    // this.apppage = 'dash';

    let params = new HttpParams().append("tablename", this.tablename);
    params = params.append("apppage", this.apppage);
    params = params.append("apppageview", this.apppageview);

    return this.http.get<any[]>(`${this.apiUrl}/getcharts`, { params });

  }



  // get chart data 
  getAppData(search: any): Observable<any[]> {

    this.tablename = 'invoice';
    this.apppage = 'dash';

    let params = new HttpParams().append("search", search);
    params = params.append("tablename", this.tablename);
    params = params.append("apppage", this.apppage);

    return this.http.get<any[]>(`${this.apiUrl}/getappdata`, { params });

  }



}