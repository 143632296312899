
import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataService } from '../data-service/data.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import { LoadingSpinnerComponent } from '../loading-spinner/loading-spinner.component';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthenticationService } from '../_services';
import { Observable, BehaviorSubject } from 'rxjs';


@Component({
  selector: 'app-list-view',
  templateUrl: './list-view.component.html',
  styleUrls: ['./list-view.component.css']
})
export class ListViewComponent implements OnInit {

  displayedColumns = [];
  data = [];
  dataSource = new MatTableDataSource();

  private searchControl: FormControl;
  private debounce: number = 400;

  showSpinner: boolean = true;

  searchfield: string;
  public search: any;
  public table: any;
  public qParms: any; 
  public addRoute: any;
  public editRoute: any;
  public selectRoute: any;
  public menuDesc: any;
  saveSearchValue: string;
  sharedSearch: string;
  saveParmValue: string;
  sharedParm: string;
  private subCall: any;

  public form: FormGroup = new FormGroup({
    searchfield: new FormControl(''),
  });

  // sharing variable value between components
  private sharingSource = new BehaviorSubject<string>("default value");
  currentMessage = this.sharingSource.asObservable();

  // sharing variable value between components
  private sharingSearch = new BehaviorSubject<string>("");
  currentSearch = this.sharingSearch.asObservable();

   // changevalue when this function is called
  changesearchValue(sharedsearchvalue: string) {
  this.sharingSearch.next(sharedsearchvalue)
  }
  
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;


  constructor(private _dataService: DataService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute) { }
    

  ngOnInit() {

    // Reuse same route when parameters change
    {this.router.routeReuseStrategy.shouldReuseRoute = () => {
     return false;
    }
  
    this.router.events.subscribe((evt) => {
        if (evt instanceof NavigationEnd) {
          this.router.navigated = false;
        }
      });
    }


    this._dataService.currentSearch.subscribe(sharedSearch => this.sharedSearch = sharedSearch)
    //this.search = parseInt(this.route.snapshot.paramMap.get('search'));
    this.search = this.route.snapshot.paramMap.get('search')
    this.qParms = this.route.snapshot.queryParams['qParms'] || 0;
    
    this.table  = this.search; 

    // save table value to be retreived on the next page
    this.saveSearchValue = this.table;
    // move from json to variable
    this.sharedSearch = this.saveSearchValue;
    // change shared variable value which can be accessed from all components
    this._dataService.changesearchValue(this.sharedSearch);


    // save table value to be retreived on the next page
    this.saveParmValue = this.qParms;
    // move from json to variable
    this.sharedParm = this.saveParmValue;
    // change shared variable value which can be accessed from all components
    this._dataService.changeParmsValue(this.sharedParm);

    // get menu record for further routing 
    this.getSelectMenuRecord()
        
    // get data list 
    this.getTableList();

  }

  getSelectMenuRecord() {
    this.subCall = this._dataService.getTableRecord(this.search, 'menu').subscribe(data => 
      {  this.selectRoute = data[0].appmenuonselect;
         this.editRoute = data[0].appmenuonedit;
         this.addRoute = data[0].appmenuonadd;
         this.menuDesc = data[0].appmenudesc; 
        }
        
      );
  }
  

  // get main data list
  getTableList() {
    
  //  this.search = 'reports'; 

    this.subCall = this._dataService.getTableList(this.search).subscribe(
      data => this.dataSource.data = data,
      err => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 403) {
            this.authenticationService.logout();
            this.router.navigate(['/login'])
          }
        }
      });

      this.subCall = this._dataService.getTableList(this.search).subscribe(data => this.getColHeadings(data));

    // show show spinner while gettng data 
    // this.subCall = this._dataService.getSearch(this.search).subscribe(() => this.showSpinner = false);

  }

  // get data column headings 
  getColHeadings(data) {

    this.data = data;

   // console.log(data);

    let cols = [];

    // let body = JSON.stringify(data); 
    cols.push('Action');

    for (var key in data[0]) {
      // console.log(key);
      cols.push(key);
    }

    if (cols != []) this.displayedColumns = cols;

    this.dataSource.data = data;

   // console.log(data);
    // this.dataSource = new MatTableDataSource(data);
  }

  onSelect(search) {
    //this._dataService.addHeaderRecord = false;
    this.router.navigate([this.selectRoute, search]);
  }

  onAdd() {
    // this._dataService.addHeaderRecord  = true; 
    // this._dataService.editHeaderRecord = false; 
    // this._dataService.addDetailRecord  = false; 
    this.router.navigate([this.addRoute,  '0']);
  }

  onEdit(search) {
    // this._dataService.editHeaderRecord  = true; 
    // this._dataService.addHeaderRecord   = false;
    // this._dataService.editDetailRecord  = false;
    // this._dataService.addDetailRecord   = false; 
    this.router.navigate([this.editRoute, search]);
  }

  // onSearch(search: string ){
  onSearch(searchValue: string) {
    // search = 'Khan'; 


    //    // move wasnbr from json to variable
    //    this.saveSearchValue = searchValue;


    // // change shared variable value which can be accessed from all components
    //    this._dataService.changeValue(this.saveSearchValue);

    //console.log("search value :" + searchValue);

    // if ($event.timestamp - this.lastKeypress > 200) {

    // this.search  = $event.target.value ; 
    this.search = searchValue;

   // console.log("search   :" + this.search);


    this.search = this.search.trim();
    this.search = this.search.toLocaleLowerCase();

    // let debounce = search.valueChanges.pipe(
    //   debounceTime(1000), // delay 1000 msec
    //   distinctUntilChanged() 
    // ) ;  

    //   debounce.subscribe(changes => {
    //     console.log(changes);
    //   });


    // console.log("search input field: " + this.search);

    // console.log("search input savedsearchinput: " + this.saveSearchValue);


    if (this.search !== this.saveSearchValue) {

      this.showSpinner = true;
    }

    // if (this.search.length>0) {

    this.subCall = this._dataService.getSearch(this.search).subscribe(
      data => this.dataSource.data = data,
      err => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 403) {
            this.authenticationService.logout();
            this.router.navigate(['/login'])
          }
        }
      });


    // show show spinner while gettng data 
    this.subCall = this._dataService.getSearch(this.search).subscribe(() => this.showSpinner = false);

    this.saveSearchValue = this.search;


    // move wasnbr from json to variable
    this.sharedSearch = this.saveSearchValue;

    // change shared variable value which can be accessed from all components
    this._dataService.changesearchValue(this.sharedSearch);
    // }

  }



  /**
   * Set the paginator and sort after the view init since this component will
   * be able to query its view for the initialized paginator and sort.
   */


  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }


  public ngOnDestroy(): void {

    this.subCall.unsubscribe();

  }

}