import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { MainNavComponent } from './main-nav/main-nav.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ReportsListComponent } from './reports/report-list.component';
import { ScannedFormsComponent } from './scannedforms/scannedforms.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TextareaAutosizeModule } from 'ngx-textarea-autosize';
import { RecordPanelComponent } from './record-panel/record-panel.component';


import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'; 
import { DataService } from './data-service/data.service';
import { DataListComponent } from './data-list/data-list.component'
import { DataDetailComponent } from './data-detail/data-detail.component'; 
import { DataDetailListComponent } from './data-detail-list/data-detail-list.component';
import { TabsComponent } from './tab-control/tabs.component';
// directives
import { TabsDirective } from './tab-control/tabs.directive';

// services
import { TabPanesService } from './tab-control/tab-panes.service';
import { TabItemsService } from './tab-control/tab-items.service';

// routing
import { routing }        from './app.routing';

//login
import { LoginComponent } from './login';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertService, AuthenticationService, UserService } from './_services';

// import { AuthGuard } from './_guards';
import { AuthLogin, AuthGuard } from './_guards';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';

//token
import { TokenInterceptorService } from './_services/token-interceptor.service'
import { AuthErrorHandler } from './_services/authErrorHandler';
import { CreateInvoiceComponent } from './create-invoice/create-invoice.component';
import { PrintViewComponent } from './print-view/print-view.component';


import { InputComponent } from "./components/input/input.component";
import { TextareaComponent } from "./components/textarea/textarea.component";
import { ButtonComponent } from "./components/button/button.component";
import { SelectComponent } from "./components/select/select.component";
import { DateComponent } from "./components/date/date.component";
import { RadiobuttonComponent } from "./components/radiobutton/radiobutton.component";
import { CheckboxComponent } from "./components/checkbox/checkbox.component";
import { DynamicFieldDirective } from "./components/dynamic-field/dynamic-field.directive";
import { DynamicFormComponent } from "./components/dynamic-form/dynamic-form.component";


// Error handler
import { ErrorHandler } from '@angular/core';

import { ReportViewComponent } from './report-view/report-view.component';
import { ListViewComponent } from './list-view/list-view.component';

//charts
// import { ChartsModule } from 'ng2-charts/ng2-charts';
 


@NgModule({
  declarations: [
    ListViewComponent,
    ReportViewComponent,
    RecordPanelComponent,
    ReportViewComponent,
    AppComponent,
    MainNavComponent,
    DashboardComponent,
    ReportsListComponent,
    ScannedFormsComponent,
    DataListComponent,
    DataDetailComponent,
    DataDetailListComponent,
    TabsComponent,
    TabsDirective,
    LoginComponent,
    LoadingSpinnerComponent,
    CreateInvoiceComponent,
    PrintViewComponent,
    InputComponent,
    TextareaComponent,
    ButtonComponent,
    SelectComponent,
    DateComponent,
    RadiobuttonComponent,
    CheckboxComponent,
    DynamicFieldDirective,
    DynamicFormComponent
    ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatTabsModule,
    MatExpansionModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatDatepickerModule,
    MatBadgeModule,
    MatAutocompleteModule,
    MatBottomSheetModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDialogModule,
    MatDividerModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatRadioModule,
    MatRippleModule,
    MatProgressSpinnerModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatTreeModule,
    MatStepperModule,
    MatSortModule,
    MatTableModule,
    MatButtonModule,
    MatCardModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatSelectModule,
    MatSidenavModule,
    MatTabsModule,
    MatToolbarModule,
    routing,
    TextareaAutosizeModule

  ],
   providers: [
    AuthErrorHandler, 
    AuthLogin,
    AuthGuard,
    AlertService,
    AuthenticationService,
    UserService,
    DataService, TabPanesService, TabItemsService,
  
  
    // {provide: ErrorHandler, useClass: AuthErrorHandler},
  
  
    {
     provide: HTTP_INTERCEPTORS,
     useClass: TokenInterceptorService,
     multi: true
  }

 

],
  // providers: [],
  bootstrap: [AppComponent],
  entryComponents: [
    InputComponent,
    TextareaComponent,
    ButtonComponent,
    SelectComponent,
    DateComponent,
    RadiobuttonComponent,
    CheckboxComponent
  ]
})
export class AppModule {}


